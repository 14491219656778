import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet } from 'react-router-dom';
import PasswordGenerator from './PasswordGenerator';
import Base64Converter from './Base64Converter';
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse
} from 'reactstrap';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Container>
        <Navbar color="light" expand="md" className="mb-3">
          <NavbarBrand href="/">React Tools</NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/password-generator" onClick={toggleNavbar}>
                  Password Generator
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/base64-converter" onClick={toggleNavbar}>
                  Base64 Converter
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/guid-generator" onClick={toggleNavbar}>
                  Guid Generator
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/string-utilities" onClick={toggleNavbar}>
                  String utilities
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </>
  );
};

export default Menu;
