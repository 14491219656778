import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './components/Menu';
import { Outlet } from 'react-router-dom';

import Home from './components/Home';
import Base64Converter from './components/Base64Converter';
import PasswordGenerator from './components/PasswordGenerator';
import GuidGenerator from './components/GuidGenerator';
import StringUtilities from './components/StringUtilities';

import { Routes, Route } from 'react-router-dom';

import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'; // Assuming you're using Reactstrap
import QRCode from 'qrcode.react'; // Import QRCode component
import React, { useState } from 'react';

function App() {
  // Define your Bitcoin and Dogecoin wallet addresses
  const dogecoinWalletAddress = 'DNXMq6MqTeQme8nj246f7axt1x6eKdC6vN';

  // State to control modal visibility and selected donation option
  const [modal, setModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('bitcoin');

  // Function to toggle modal
  const toggleModal = () => setModal(!modal);

  // Function to handle donation button click
  const handleDonate = (option) => {
    setSelectedOption(option);
    toggleModal();
  };

  return (
    <div className="App">
      <Menu />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/base64-converter" element={<Base64Converter />} />
        <Route path="/password-generator" element={<PasswordGenerator />} />
        <Route path="/guid-generator" element={<GuidGenerator />} />
        <Route path="/string-utilities" element={<StringUtilities />} />
      </Routes>

      <Outlet />

      <footer className="footer mt-5">
        {/* Donation Button */}
        <Container className="mt-5">
          <Row>
            <Col md={{ size: 6, offset: 3 }} className="text-center">
              <p>Enjoy using React Tools? Your support helps us continue providing these tools for free!</p>
              {/* Donation Option Button */}
              <Button color="warning" onClick={toggleModal}>Donate Dogecoin</Button>
            </Col>
          </Row>
        </Container>

        {/* QR Code and Wallet Address Modal */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Cryptocurrency Donation</ModalHeader>
          <ModalBody>
            <p>Scan the QR code or copy the wallet address to make a donation:</p>
            {/* Dogecoin QR Code */}
            <div className="text-center">
              <QRCode value={dogecoinWalletAddress} />
            </div>
            {/* Dogecoin wallet address */}
            <p className="mt-3">Dogecoin Wallet: {dogecoinWalletAddress}</p>
          </ModalBody>
        </Modal>
      </footer>
    </div>
  );
}

export default App;
