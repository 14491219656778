import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

const LandingPage = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h3" className="text-center">
                Welcome to React Tools!
              </CardTitle>
              <p className="text-center">
                Explore the following tools to make your life easier:
              </p>
              <ul>
                <li>
                  <Link to="/password-generator">Password Generator</Link>
                </li>
                <li>
                  <Link to="/base64-converter">Base64 Converter</Link>
                </li>
                <li>
                  <Link to="/guid-generator">Guid Generator</Link>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
