import React, { useState } from 'react';
import { Container, Row, Col, Input, Button, Card, CardBody, CardTitle, FormGroup, Label } from 'reactstrap';

const StringUtilities = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [lineCount, setLineCount] = useState(0);
  const [substring, setSubstring] = useState('');
  const [containsFlag, setContainsFlag] = useState(false);
  const [reuseOutput, setReuseOutput] = useState(false);
  const [previousOperation, setPreviousOperation] = useState(null);
  const [splitSeparator, setSplitSeparator] = useState(' ');

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setInputText(newText);
    setCharCount(newText.length);
    setWordCount(newText.trim().split(/\s+/).filter(word => word !== '').length);
    setLineCount(newText.split(/\r\n|\r|\n/).filter(line => line !== '').length);
    if (!reuseOutput) {
      setOutputText('');
    }
  };

  const checkContains = () => {
    const contains = inputText.toLowerCase().includes(substring.toLowerCase());
    setContainsFlag(contains);
    setPreviousOperation('contains');
  };

  const splitString = () => {
    handleOutput(inputText.split(splitSeparator).join('\n'));
    setPreviousOperation('split');
  };

  const toUpperCase = () => {
    handleOutput(inputText.toUpperCase());
    setPreviousOperation('uppercase');
  };

  const toLowerCase = () => {
    handleOutput(inputText.toLowerCase());
    setPreviousOperation('lowercase');
  };

  const reverseString = () => {
    handleOutput(inputText.split('').reverse().join(''));
    setPreviousOperation('reverse');
  };

  const capitalizeWords = () => {
    handleOutput(inputText.replace(/\b\w/g, (char) => char.toUpperCase()));
    setPreviousOperation('capitalize');
  };

  const inputStyle = {
    backgroundColor: containsFlag ? 'lightgreen' : 'salmon',
  };

  const handleReuseOutputChange = () => {
    setReuseOutput(!reuseOutput);
    if (!reuseOutput) {
      setPreviousOperation(null);
    }
  };

  const handleOutput = (text) => {
    if (reuseOutput && previousOperation !== null) {
      setOutputText(text);
      setInputText(text);
    } else {
      setOutputText(text);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h3" className="text-center">String Utilities</CardTitle>
              <Row>
                <Col>
                  <Input type="textarea" value={inputText} onChange={handleTextChange} placeholder="Enter your text" style={{ height: "100px" }} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Input type="text" placeholder="Enter substring" value={substring} onChange={(e) => setSubstring(e.target.value)} style={inputStyle} />
                </Col>
                <Col>
                  <Button color="primary" onClick={checkContains}>Contains</Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Input type="text" placeholder="Enter separator" value={splitSeparator} onChange={(e) => setSplitSeparator(e.target.value)} />
                </Col>
                <Col>
                  <Button color="primary" onClick={splitString}>Split</Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button color="primary" className="ml-2" onClick={toUpperCase} style={{ marginRight: "5px" }}>Uppercase</Button>
                  <Button color="primary" className="ml-2" onClick={toLowerCase} style={{ marginRight: "5px" }}>Lowercase</Button>
                  <Button color="primary" className="ml-2" onClick={reverseString} style={{ marginRight: "5px" }}>Reverse</Button>
                  <Button color="primary" className="ml-2" onClick={capitalizeWords}>Capitalize Words</Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Input type="textarea" value={outputText} readOnly style={{ height: "100px" }} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" checked={reuseOutput} onChange={handleReuseOutputChange} /> Reuse Output
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div>Character Count: {charCount}</div>
                  <div>Word Count: {wordCount}</div>
                  <div>Line Count: {lineCount}</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StringUtilities;
