import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FaCopy } from 'react-icons/fa';

const Base64Converter = () => {
  const [inputString, setInputString] = useState('');
  const [outputString, setOutputString] = useState('');
  const [isEncoding, setIsEncoding] = useState(true);

  const handleInputChange = (e) => {
    setInputString(e.target.value);
  };

  const handleToggleEncoding = () => {
    setIsEncoding(!isEncoding);
    setOutputString(''); // Clear output when toggling encoding/decoding
  };

  const handleConvert = () => {
    try {
      if (isEncoding) {
        const encodedString = btoa(inputString);
        setOutputString(encodedString);
      } else {
        const decodedString = atob(inputString);
        setOutputString(decodedString);
      }
    } catch (error) {
      setOutputString('Error: Invalid input for conversion');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(outputString);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h3" className="text-center">
                Base64 Converter
              </CardTitle>
              <FormGroup>
                <Label>Input String:</Label>
                <Input type="text" value={inputString} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={isEncoding} onChange={handleToggleEncoding} />
                  Encode
                </Label>
              </FormGroup>
              <Button color="primary" block onClick={handleConvert}>
                {isEncoding ? 'Encode' : 'Decode'}
              </Button>
              {outputString && (
                <div className="mt-3 text-center">
                  <Button color="secondary" onClick={copyToClipboard}>
                    <FaCopy className="mr-1" />
                    Copy to Clipboard
                  </Button>
                </div>
              )}
              {outputString && (
                <div className="mt-3">
                  <h4 className="text-center">{isEncoding ? 'Encoded' : 'Decoded'} String:</h4>
                  <p className="text-center">{outputString}</p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Base64Converter;
