import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FaCopy } from 'react-icons/fa';

const PasswordGenerator = () => {
  const [passwords, setPasswords] = useState([]);
  const [length, setLength] = useState(8);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSpecialChars, setIncludeSpecialChars] = useState(true);
  const [passwordsAmount, setPasswordsAmount] = useState(1);

  const generatePassword = () => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

    let validChars = '';

    if (includeUppercase) {
      validChars += uppercaseChars;
    }

    if (includeLowercase) {
      validChars += lowercaseChars;
    }

    if (includeNumbers) {
      validChars += numberChars;
    }

    if (includeSpecialChars) {
      validChars += specialChars;
    }

    const generateSinglePassword = () => {
      let newPassword = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * validChars.length);
        newPassword += validChars[randomIndex];
      }
      return newPassword;
    };

    const generatedPasswords = Array.from({ length: passwordsAmount }, generateSinglePassword);
    setPasswords(generatedPasswords);
  };

  const copyToClipboard = () => {
    const textToCopy = passwords.join('\n');
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h3" className="text-center">
                Password Generator
              </CardTitle>
              <FormGroup>
                <Label>Password Length:</Label>
                <Input
                  type="number"
                  value={length}
                  onChange={(e) => setLength(Math.max(1, parseInt(e.target.value) || 0))}
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={includeUppercase}
                    onChange={() => setIncludeUppercase(!includeUppercase)}
                  />
                  Include Uppercase Letters
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={includeLowercase}
                    onChange={() => setIncludeLowercase(!includeLowercase)}
                  />
                  Include Lowercase Letters
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={includeNumbers}
                    onChange={() => setIncludeNumbers(!includeNumbers)}
                  />
                  Include Numbers
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={includeSpecialChars}
                    onChange={() => setIncludeSpecialChars(!includeSpecialChars)}
                  />
                  Include Special Characters
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>Amount of Passwords:</Label>
                <Input
                  type="number"
                  value={passwordsAmount}
                  onChange={(e) => setPasswordsAmount(Math.max(1, parseInt(e.target.value) || 0))}
                />
              </FormGroup>
              <Button color="primary" block onClick={generatePassword}>
                Generate Passwords
              </Button>
              {passwords.length > 0 && (
                <div className="mt-3 text-center">
                  <Button color="secondary" onClick={copyToClipboard}>
                    <FaCopy className="mr-1" />
                    Copy to Clipboard
                  </Button>
                </div>
              )}
              {passwords.length > 0 && (
                <div className="mt-3">
                  <h4 className="text-center">Generated Passwords:</h4>
                  <ul className="text-center">
                    {passwords.map((password, index) => (
                      <li key={index}>{password}</li>
                    ))}
                  </ul>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordGenerator;
