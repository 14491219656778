import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Label, Input } from 'reactstrap';
import { FaCopy } from 'react-icons/fa';

const GuidGenerator = () => {
  const [generatedGuids, setGeneratedGuids] = useState([]);
  const [useUppercase, setUseUppercase] = useState(false);
  const [useBraces, setUseBraces] = useState(false);
  const [guidsAmount, setGuidsAmount] = useState(1);

  const generateGuid = () => {
    const generateSingleGuid = () => {
      const pattern = useBraces ? '{xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx}' : 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
      const guid = pattern.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });

      return useUppercase ? guid.toUpperCase() : guid;
    };

    const guids = Array.from({ length: guidsAmount }, generateSingleGuid);
    setGeneratedGuids(guids);
  };

  const copyToClipboard = () => {
    const textToCopy = generatedGuids.join('\n');
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h3" className="text-center">
                GUID Generator
              </CardTitle>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={useUppercase}
                    onChange={() => setUseUppercase(!useUppercase)}
                  />
                  Use Uppercase
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={useBraces}
                    onChange={() => setUseBraces(!useBraces)}
                  />
                  Use Braces
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>Amount of GUIDs:</Label>
                <Input
                  type="number"
                  value={guidsAmount}
                  onChange={(e) => setGuidsAmount(Math.max(1, parseInt(e.target.value) || 0))}
                />
              </FormGroup>
              <Button color="primary" block onClick={generateGuid}>
                Generate GUIDs
              </Button>
              {generatedGuids.length > 0 && (
                <div className="mt-3 text-center">
                  <Button color="secondary" onClick={copyToClipboard}>
                    <FaCopy className="mr-1" />
                    Copy to Clipboard
                  </Button>
                </div>
              )}
              {generatedGuids.length > 0 && (
                <div className="mt-3">
                  <h4 className="text-center">Generated GUIDs:</h4>
                  <ul className="text-center">
                    {generatedGuids.map((guid, index) => (
                      <li key={index}>{guid}</li>
                    ))}
                  </ul>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GuidGenerator;
